import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./index@gen.scss";

const GeneratedAutomationRunbookIndex = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          PagerDuty Runbook Automation｜正規販売代理店（株）Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="PagerDuty Rubook Automationでは、マニュアルの手順書をセルフサービス型の自動タスクに置き換えることで、より迅速な運用が実現します。今すぐお試しください。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="PagerDuty Rubook Automationでは、マニュアルの手順書をセルフサービス型の自動タスクに置き換えることで、より迅速な運用が実現します。今すぐお試しください。"
        />

        <meta
          property="og:title"
          content="PagerDuty Runbook Automation｜正規販売代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="PagerDuty Rubook Automationでは、マニュアルの手順書をセルフサービス型の自動タスクに置き換えることで、より迅速な運用が実現します。今すぐお試しください。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/automation/runbook//"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/automation/runbook//"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Automation"
        textGreen="PagerDuty Runbook Automation"
        overview=""
      />

      <div className="itoperationsWrapper">
        <div className="itoperations-container">
          <div className="mt-0 center-1">
            <h3 className="center-title md:mb-[0px] mb-[30px]">
              ランブックのマニュアル手順を、自動化されたセルフサービスタスクに置き換えることができます。
            </h3>
          </div>
          <div className="three-col-blog-card-image-grey bg-transparent py-0 my-0 md:mt-[50px]">
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  className="w-[80px] aspect-square"
                  xmlns="http://www.w3.org/2000/svg"
                  width={80}
                  height={100}
                  viewBox="0 0 80 100"
                  fill="none"
                >
                  <path
                    opacity="0.942"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M37.0768 0C39.026 0 40.9747 0 42.9239 0C49.9162 2.66315 52.4915 7.48186 50.6505 14.4562C49.6063 16.5432 48.0748 18.2362 46.0563 19.5354C45.8483 20.7003 45.7786 21.8725 45.8475 23.0518C41.9495 23.0518 38.0512 23.0518 34.1533 23.0518C34.8265 20.5042 33.9215 18.6809 31.4385 17.5819C28.1612 13.3452 27.8831 8.91717 30.6032 4.29779C32.3465 2.22173 34.5041 0.789129 37.0768 0ZM39.1651 2.73496C45.9001 3.21402 48.7539 6.60015 47.7269 12.8934C47.2758 13.8686 46.7187 14.7803 46.0563 15.6283C45.8479 13.9402 45.7782 12.2472 45.8475 10.5491C41.9495 10.5491 38.0512 10.5491 34.1533 10.5491C34.2226 12.2472 34.1528 13.9402 33.9444 15.6283C31.7785 12.6708 31.4999 9.54515 33.1091 6.25133C34.6515 4.32686 36.6704 3.15473 39.1651 2.73496Z"
                    fill="#4EB346"
                  />
                  <path
                    opacity="0.946"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.09418 22.661C11.4556 24.8713 13.6829 27.2156 15.7766 29.6938C13.8308 31.4481 11.8123 33.1411 9.72066 34.773C7.41356 32.4721 5.25597 30.0627 3.24707 27.5449C5.27727 25.9695 7.22645 24.3416 9.09418 22.661Z"
                    fill="#4EB346"
                  />
                  <path
                    opacity="0.948"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M70.4889 22.661C72.6302 24.2064 74.7184 25.8343 76.7536 27.5449C74.7447 30.0627 72.5872 32.4721 70.2801 34.773C68.1885 33.1411 66.17 31.4481 64.2241 29.6938C66.322 27.342 68.4102 24.9977 70.4889 22.661Z"
                    fill="#4EB346"
                  />
                  <path
                    opacity="0.972"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M35.8239 25.0053C56.3322 24.5314 70.463 33.127 78.2154 50.7921C83.3413 69.4902 77.4248 84.2722 60.4653 95.1374C47.1652 101.488 33.8004 101.618 20.3708 95.5281C2.76973 84.818 -3.42528 69.9059 1.78533 50.7921C7.83834 36.0197 19.1847 27.4241 35.8239 25.0053ZM36.2415 34.3823C36.7983 34.3823 37.3554 34.3823 37.9121 34.3823C37.9121 36.2056 37.9121 38.0289 37.9121 39.8522C39.3042 39.8522 40.6966 39.8522 42.0886 39.8522C42.0886 38.0289 42.0886 36.2056 42.0886 34.3823C58.5161 36.4661 67.8439 45.192 70.0713 60.5598C68.1221 60.5598 66.1733 60.5598 64.2241 60.5598C64.2241 61.862 64.2241 63.1646 64.2241 64.4668C66.1733 64.4668 68.1221 64.4668 70.0713 64.4668C69.6461 70.481 67.4188 75.8208 63.3888 80.4859C63.1792 80.8848 62.901 80.95 62.5535 80.6812C61.3637 79.5028 60.1107 78.3956 58.7947 77.3602C57.4402 78.1604 56.5352 79.2024 56.0799 80.4859C57.5776 81.6127 58.6911 82.9149 59.4212 84.393C54.3233 88.1312 48.5459 90.2153 42.0886 90.6443C42.0886 88.8208 42.0886 86.9978 42.0886 85.1744C40.6966 85.1744 39.3042 85.1744 37.9121 85.1744C37.9121 86.9978 37.9121 88.8208 37.9121 90.6443C31.4269 90.402 25.7188 88.3184 20.7884 84.393C20.362 84.1968 20.2923 83.9366 20.5796 83.6115C22.0773 82.4847 23.1908 81.1825 23.9208 79.7045C23.0788 78.8512 22.1738 78.0697 21.2061 77.3602C19.8901 78.3956 18.6371 79.5028 17.4472 80.6812C17.0998 80.95 16.8216 80.8848 16.6119 80.4859C12.5808 75.8165 10.3534 70.4767 9.92952 64.4668C11.8787 64.4668 13.8275 64.4668 15.7766 64.4668C15.7766 63.1646 15.7766 61.862 15.7766 60.5598C13.8275 60.5598 11.8787 60.5598 9.92952 60.5598C10.4115 54.3194 12.8481 48.8495 17.2384 44.15C18.6304 45.4523 20.0229 46.7549 21.4149 48.0571C22.3964 47.2042 23.3015 46.2927 24.1297 45.3221C22.8136 43.8957 21.4216 42.5282 19.9531 41.2197C24.8025 37.6476 30.232 35.3684 36.2415 34.3823Z"
                    fill="#4EB346"
                  />
                  <path
                    opacity="0.945"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M58.3762 43.3686C60.5225 42.9607 61.149 43.612 60.2556 45.3221C55.2438 50.792 50.232 56.2619 45.2202 61.7319C44.7641 66.3387 42.1885 67.9664 37.4937 66.6157C34.1571 63.8975 34.1571 61.1626 37.4937 58.4108C38.9066 58.069 40.2986 57.6783 41.6702 57.2387C47.3477 52.7002 52.9163 48.077 58.3762 43.3686Z"
                    fill="#4A4A4A"
                  />
                </svg>
              </div>
              <h4 className="title">
                手順の自動化によりリクエストを数分で解決
              </h4>
              <p className="para">
                開発者向けの開発環境のプロビジョニングなど、クラウドプラットフォーム向けのセルフサービス型タスクを自動化し、関係者に対応を委ねることができます。
              </p>
            </div>
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  className="w-[80px] aspect-square"
                  xmlns="http://www.w3.org/2000/svg"
                  width={80}
                  height={100}
                  viewBox="0 0 80 100"
                  fill="none"
                >
                  <path
                    opacity="0.989"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39.6003 0C39.8669 0 40.1331 0 40.3997 0C49.6939 13.8496 62.8857 20.0996 79.975 18.75C80.0417 32.5527 79.975 46.3547 79.7751 60.1562C78.3928 66.2422 75.728 71.7109 71.7801 76.5625C62.9516 86.6902 52.3583 94.5027 40 100C26.3134 93.9848 14.9205 85.1305 5.82141 73.4375C2.9556 69.2691 1.08997 64.7117 0.224915 59.7656C0.0250406 46.0945 -0.0417176 32.4225 0.0250406 18.75C17.1143 20.0998 30.3061 13.8498 39.6003 0ZM39.6003 13.6719C39.9992 25.9748 40.1327 38.3447 40 50.7812C29.6065 50.7812 19.213 50.7812 8.81953 50.7812C8.68642 42.9645 8.81953 35.1519 9.21928 27.3438C18.2624 26.6771 26.524 23.8777 34.0038 18.9453C36.0701 17.3816 37.9357 15.6237 39.6003 13.6719ZM40 51.1719C50.3935 51.1719 60.787 51.1719 71.1805 51.1719C71.8608 56.7672 70.7284 61.9754 67.7826 66.7969C64.282 72.3922 59.818 77.1445 54.391 81.0547C49.8774 84.4215 45.1472 87.4812 40.1999 90.2344C40 77.2141 39.9332 64.1934 40 51.1719Z"
                    fill="#4EB346"
                  />
                </svg>
              </div>
              <h4 className="title">
                セキュリティーとコンプライアンスを最適化
              </h4>
              <p className="para">
                認証、アクセス制御、全アクティビティーログ、コンテキストチェックにより、適切なアクションを実行可能にし、安全性とコンプライアンスを確保します。
              </p>
            </div>
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 80 80"
                  fill="none"
                  className="w-[80px] aspect-square"
                >
                  <path
                    opacity="0.969"
                    fillrule="evenodd"
                    cliprule="evenodd"
                    d="M38.431 0C40.5139 0 42.5965 0 44.6794 0C61.2425 2.73982 72.4376 12.0007 78.2642 27.7828C82.7168 44.5901 78.6032 58.8976 65.9237 70.7056C54.1903 79.8573 41.1728 82.2507 26.8716 77.8854C9.73111 71.0564 0.775361 58.4137 0.0036906 39.9572C-0.100032 31.9967 1.98254 24.6608 6.25204 17.9495C5.46506 17.0615 4.57998 16.281 3.59649 15.6083C3.38811 15.088 3.38811 14.5677 3.59649 14.0475C8.58267 11.2183 13.6332 8.51286 18.7487 5.93115C19.6357 6.08783 20.0521 6.60811 19.9984 7.49198C18.9059 12.6921 17.8124 17.8948 16.718 23.1003C16.5056 23.7287 16.0891 24.1449 15.4683 24.3489C14.3964 23.7992 13.4073 23.1229 12.5004 22.3199C12.1458 22.0859 11.8334 22.138 11.5631 22.4759C4.96082 34.0851 4.90895 45.7392 11.4069 57.4385C19.7241 69.578 31.2314 74.7288 45.929 72.8907C60.5605 69.509 69.5687 60.508 72.9531 45.8884C74.7926 31.2026 69.6377 19.7045 57.4885 11.3941C51.5616 8.10205 45.2092 6.3331 38.431 6.08723C35.9654 4.08154 35.9654 2.05247 38.431 0Z"
                    fill="#4EB346"
                  />
                  <path
                    opacity="0.964"
                    fillrule="evenodd"
                    cliprule="evenodd"
                    d="M56.5516 20.6029C58.9012 20.9256 59.5783 22.1743 58.5823 24.3489C54.534 29.3312 50.3682 34.2218 46.0856 39.0207C45.1121 44.7861 41.9357 46.607 36.5569 44.4836C35.4062 43.3295 34.5733 41.9769 34.0575 40.4255C31.0896 38.0842 28.1216 35.743 25.1536 33.4017C24.3813 31.0478 25.2145 29.591 27.653 29.0314C28.2675 29.0445 28.8405 29.2006 29.3713 29.4997C31.8706 31.2686 34.3699 33.0374 36.8693 34.8065C37.8271 34.6232 38.8163 34.4672 39.8372 34.3382C40.7348 34.3214 41.568 34.5293 42.3366 34.9626C45.3567 31.737 48.3765 28.5111 51.3967 25.2854C52.9966 23.5278 54.7149 21.967 56.5516 20.6029Z"
                    fill="#4B4B4B"
                  />
                </svg>
              </div>
              <h4 className="title">
                本業のエンジニアリングにより多くの時間を
              </h4>
              <p className="para">
                開発チームは、チケット管理や同僚からの対応リクエストに忙殺される状況から解放され、本来の主業務であるイノベーションにより集中することができます。
              </p>
            </div>
          </div>
          <div className="text-center mt-[100px] mb-[30px]">
            <h3 className="relative center-title max-w-max">
              製品機能
              <div className="absolute w-[30%] h-[1.5px] bottom-[-10px] mx-auto bg-[#4EB346]"></div>
            </h3>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[0px] md:mt-[10px]">
            <div className="pr-0 text-part">
              <p className="title">
                タスクの自動化をパブリッククラウド対応に標準化
              </p>
              <p className="para">
                共通のインフラコンポーネントをノードとして組み込んだタスク自動化を定義し、既存のスクリプトやコマンドを活用したステップを実行します。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Runbook/rb1_1_c5a3a10ad2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673302667240&tr=w-480,q-100,f-webp"
                alt="automation image"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">
                セルフサービス型クラウド運用タスクの安全な委譲
              </p>
              <p className="para">
                認証、アクセス制御、特権アクセス管理サービスで安全性とコンプライアンスを確保し、全アクティビティーを記録してタスク委譲を促進します。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Runbook/rb2_1_34d1b29f4d.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673302667200&tr=w-480,q-100,f-webp"
                alt="auto motion iamge 3"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">
                一般的なシステムおよびインフラへのプラグイン
              </p>
              <p className="para">
                各種クラウドサービス、インフラストラクチャー、監視、チケット管理、自動化ツールなどとのプラグイン／インテグレーション連携が可能です。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Runbook/rb3_1_5cd1990bab.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673302667275&tr=w-480,q-100,f-webp"
                alt="autoMotionImage"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">
                任意のインターフェイスから自動化を呼び出し可能
              </p>
              <p className="para">
                Web
                GUI、CLI、API、またはJiraやServiceNowなどお使いのインテグレーションアプリケーションから、自動化を呼び出すことができます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Runbook/rb4_1_b9ec1b310d.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673302667464&tr=w-480,q-100,f-webp"
                alt="autoMotionImage 4"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">
                すぐ使える高可用性かつ自己管理型のデプロイメント
              </p>
              <p className="para">
                DevOpsエンジニアやSREは、自動化インフラを管理することなく、高い安全性と可用性を備えた、信頼性の高い自動化プロセスを提供できます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Runbook/rb5_1_6991b9892d.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673302667280&tr=w-480,q-100,f-webp"
                alt="auttion"
              />
            </div>
          </div>
          <div className="center-2">
            <h3 className="center-title">リモート環境に安全に接続します</h3>
            <p className="center-para">
              Runbook
              Automationは、ファイアウォールまたはVPC内にデプロイされたRunbook
              Runnerを通じてITインフラストラクチャーに接続し、Runbook
              Automationのエンドポイントに戻る暗号化された接続を提供します。Runbook
              Runnerは、最新のゼロトラストセキュリティーモデルに適合するように構築されています。HTTPS経由でRunbook
              Automationのエンドポイントにコールバックし、ファイアウォールに追加のポートを開く必要はありません。
            </p>
          </div>
          <img
            className="mb-[80px] p-4 md:p-0 md:mt-[30px]"
            src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/automation_6f4dc921cb.svg?updated_at=2022-09-13T06:55:04.321Z"
            alt="automation"
          />
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedAutomationRunbookIndex;
